import { Suspense, useRef, useEffect, useState } from 'react'
import { Canvas } from '@react-three/fiber';
import { useGLTF, OrbitControls, Center, Stage, Text, Html } from '@react-three/drei';
import Frame from "./spatialFrame.jsx";
import Navbar from './Navbar.jsx'
import MoveCamera from './MoveCamera.jsx'

export default function Experience() {
    const gltf = useGLTF('./model/my_virtual_desk.glb', true); // Ensure the path is correct
    const [homePan, setHomePan] = useState(false)
    const [screen1Pan, setScreen1Pan] = useState(false)
    const [screen2Pan, setScreen2Pan] = useState(false)
    const [screen3Pan, setScreen3Pan] = useState(false)
    const [panInProgress, setPanInProgress] = useState(false)
    const [fullscreen, setFullscreen] = useState(false)
    const controls  = useRef()
    
    useEffect(() => {
        const printCameraPosition = () => {
            if (controls.current) {
                if (controls.current.object.position.y < 200){
                    console.log("Camera position: ", controls.current.object.position);
                }
                console.log("Camera target: ", controls.current.target);
            }
        }
        const interval = setInterval(printCameraPosition, 1000);
        return () => clearInterval(interval);
    }, []);

    if (controls.current) {
        if (fullscreen) {
            controls.current.enabled = false;
        } else {
            controls.current.enabled = true;
        }
      }

    return (
        <>
        <Canvas className='z-10' gl={{ antialias: false }} camera={{ position: [-2.5, 3, 6], fov: 50 }}>
                {homePan && 
                    <MoveCamera 
                        controls={controls} 
                        position={[
                            -103.56164830232643, 124.27397796279247,248.54795592558435
                        ]} 
                        target={[0,0,0]} 
                        setPan={setHomePan} 
                        setPanInProgress={setPanInProgress}
                    />}
                {screen1Pan && 
                    <MoveCamera 
                        controls={controls} 
                        position={[-14.5, 36, 4.4]} 
                        target={[-14.5,30.5,-14]} 
                        setPan={setScreen1Pan} 
                        setPanInProgress={setPanInProgress} 
                    />}
                {screen2Pan && 
                    <MoveCamera 
                        controls={controls} 
                        position={[-35,49.6,-6.23]} 
                        target={[-38,46,-43]} 
                        setPan={setScreen2Pan} 
                        setPanInProgress={setPanInProgress} 
                    />}
                {screen3Pan && 
                    <MoveCamera 
                        controls={controls} 
                        position={[11.6,47.4,5]} 
                        target={[11.7,47.4,4.11]} 
                        setPan={setScreen3Pan} 
                        setPanInProgress={setPanInProgress} 
                    />}
            <OrbitControls 
                // enableZoom={false} 
                // enablePan={false} 
                minPolarAngle={0} 
                maxPolarAngle={Math.PI / 1.8} 
                ref={controls} 
                dampingFactor={.1}
                rotateSpeed={1}
                makeDefault 
            />
            <ambientLight intensity={0.5} />
            <directionalLight position={[10, 10, 5]} intensity={1} />
            <Suspense fallback={null}>
            <Stage>
             <primitive object={gltf.scene} rotation={[0,Math.PI/2,0]}/>

            </Stage>
            <Text
                    color="red" // Text color
                    fontSize={20} // Size of the text
                    position={[-5, 90, -50]} // Adjust position as needed
                    rotation={[0, 0, 0]} // Adjust rotation as needed
                    // Additional props for font, etc.
                >
                    Daniel Portela
            </Text>
            <Frame
                frameLink={"https://daniel-website-psi.vercel.app/"} 
                position={[-14.35, 29.45, -22.2]} 
                rotation={[-Math.PI/9, 0, 0]}
                width={1425} 
                height={775}
                distanceFactor={10}
                setPanIn={setScreen1Pan}
                setPanOut={setHomePan}
                fullscreen={fullscreen}
                setFullscreen={setFullscreen}
                widthMult={0.56}
                heightMult={0.535}
            />
            <Frame
                frameLink={"https://retrofix.ai/"} 
                position={[-38.53, 45.6, -47.1]} 
                rotation={[0, Math.PI/36, 0]}
                width={2110/1.5} 
                height={1165/1.5}
                distanceFactor={15}
                setPanIn={setScreen1Pan}
                setPanOut={setHomePan}
                fullscreen={fullscreen}
                setFullscreen={setFullscreen}
                widthMult={0.56}
                heightMult={0.535}
            />


            <Frame
                frameLink={"https://www.youtube.com/embed/3GW1ojOF5c8?si=7Z_oUcyE-Xtmpi-O"} 
                position={[18.5, 45.6, -47.1]} 
                rotation={[0, -Math.PI/36, 0]}
                width={2110} 
                height={1165}
                distanceFactor={10}
                setPanIn={setScreen1Pan}
                setPanOut={setHomePan}
                fullscreen={fullscreen}
                setFullscreen={setFullscreen}
                widthMult={0.56}
                heightMult={0.535}
            />

            </Suspense>
            

        </Canvas>
        <div className='fixed z-50'>
            <Navbar setHomePan={setHomePan} screen1Pan={screen1Pan} setScreen1Pan={setScreen1Pan} screen2Pan={screen2Pan} setScreen2Pan={setScreen2Pan} screen3Pan={screen3Pan} setScreen3Pan={setScreen3Pan} panInProgress={panInProgress} setPanInProgress={setPanInProgress} />

        </div>
        </>
    );
}
