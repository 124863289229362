import { useThree, useFrame } from '@react-three/fiber';
import { useEffect } from 'react';
import * as THREE from 'three';

export default function MoveCamera( { controls, position, target, setPan, setPanInProgress }) {

    const { camera } = useThree();
    const pos = new THREE.Vector3(position[0], position[1], position[2]);  // Target position
    const targ = new THREE.Vector3(target[0], target[1], target[2]);  // Camera position
    const speed = 0.1;  // Speed of interpolation (between 0 and 1)  

    useFrame(() => {
        if (controls.current) {
    
            // Start lerping - disable controls
            controls.current.enabled = false;

            // Lerp camera position and target
            controls.current.target.lerp(targ, speed);
            camera.position.lerp(pos, speed);
            controls.current.update();
    
            // Check if lerping is finished
            if (controls.current.target.distanceTo(targ) < 0.05 && camera.position.distanceTo(pos) < 0.05) {
                console.log("controls enabled")
                setPan(false);
                setPanInProgress(false);
                controls.current.enabled = true;
            }
    
        }
    });

    return null;

}
