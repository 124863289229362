import { useState } from 'react'
import Experience from './Experience'
import LandingPage from './LandingPage'
import { useGLTF } from '@react-three/drei';


export default function Main() {
  const [isSpatial, setIsSpatial] = useState(false)

  return (
      <>
        {isSpatial ? <Experience /> : <LandingPage setIsSpatial={setIsSpatial} />}
      </>
    )
}

useGLTF.preload('./model/my_virtual_desk.glb'); // Optional: Preload your model