import React from 'react'

export default function Navbar(props) {
  return (
    <div className="select-none fixed z-50 px-4 space-x-7 bg-[#808080]/20 backdrop-blur-sm rounded-full flex items-center justify-around py-1 top-4 left-1/2 transform -translate-x-1/2">
        <NavItem text="Home" onClick={() => {props.setHomePan(true)}} panInProgress={props.panInProgress} setPanInProgress={props.setPanInProgress} />
          <NavItem text="About Me" onClick={() => {props.setScreen1Pan(true)}} panInProgress={props.panInProgress} setPanInProgress={props.setPanInProgress} />
          <NavItem text="Startup" onClick={() => {props.setScreen2Pan(true)}} panInProgress={props.panInProgress} setPanInProgress={props.setPanInProgress} />
          <NavItem text="Video" onClick={() => {props.setScreen3Pan(true)}} panInProgress={props.panInProgress} setPanInProgress={props.setPanInProgress} />
    </div>
  )
}

const NavItem = ({text, onClick: handleClick, panInProgress, setPanInProgress}) => {
  const handleOnClick = () => {
    if (handleClick && !panInProgress){
      setPanInProgress(true);
      handleClick();
    } 
  }

  return (
    <button onClick={handleOnClick} className="text-lg transition-transform transform font-weight-700 duration-200 ease-in-out drop-shadow-lg hover:scale-[1.1]">
      {text}
    </button>
  )
}