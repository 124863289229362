import React, { useState, useEffect } from 'react';

function LandingPage({setIsSpatial}) {

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    handleResize(); // Check on initial render

    window.addEventListener('resize', handleResize); // Check on window resize

    return () => {
      window.removeEventListener('resize', handleResize); // Cleanup on component unmount
    };
  }, []);

  const handleImageClick = () => {
    if (!isMobile) {
      setIsSpatial(true);
    }
  };

  return (
    <div className="flex flex-col items-center justify-start min-h-screen px-4 py-12 bg-ivory">
      <h1 className="mb-8 text-4xl font-bold text-red-700">Daniel Portela</h1>
      <p className="max-w-lg mb-12 text-lg text-center">
        Welcome to my humble website! This is just a landing page for the spatial site below. The UX is still not fully fleshed out, so enter at your own risk.
      </p>
      <strong className='max-w-xl pb-12'>Instructions: Look around with left click and drag. Click on the items in the navigation bar to pan the camera to the different screens.</strong>
      <p className='max-w-xl pb-2'>Click on the image below to enter the spatial site.</p>
      <p className='max-w-xl pb-2 text-sm'>P.S. It won't work on mobile at the moment.</p>
      <img
        src="spatial-site-screenshot.png"
        alt="An image of the spatial site that this leads to"
        className={`rounded-lg shadow-lg max-w-lg w-full ${!isMobile ? 'cursor-pointer' : ''}`}
        onClick={handleImageClick}
      />
    </div>
  );
}

export default LandingPage;